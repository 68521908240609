export default [
  {
    name: "New Forest Room",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche. That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure.You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://www.druidsglenresort.com/cmsGallery/imagerow/7664/resized/1600x900/deluxe_guest_room_landing_page_4.jpg",
    id: 1,
  },
  {
    name: "Opening of the Restaurant",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure.You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://www.visit.alsace/wp-content/uploads/lei/pictures/235000773-hotel-le-colombier-10-1600x900.jpg",
    id: 2,
  },
  {
    name: "Night of San Juan",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure. You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://cosmosnaxos.com/wp-content/uploads/2022/07/CosmosBoutiqueHotel_DeluxeVillaUpTo10_010.jpg",
    id: 3,
  },
  {
    name: "Hotel Loyalty Club",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure. You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://i.pinimg.com/originals/c6/0d/47/c60d470f1edde4b9d4ec6c88b11410e0.jpg",
    id: 4,
  },
  {
    name: "Magna pars",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure. You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://www.fourseasons.com/content/dam/fourseasons/images/web/NLN/NLN_349_aspect16x9.jpg",
    id: 5,
  },
  {
    name: "Meet new blue room",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure. You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://qtxasset.com/quartz/qcloud1/media/image/LTA_2_LGATP-Upper-Stories-King-%20%281%29.jpg?VersionId=GI2jACQTHD0hVt.SkLMvD_Vvp1ohw74o",
    id: 5,
  },
  {
    name: "Coffee bar upgrade",
    detail:
      "Some of the most successful brands in the world credit their success to their customer-centric culture. In the hospitality space, enhancing guest experience is more important now than it ever has been in the past. Now, because we at Hotelogix strongly believe in the mantra of “What is measured, is managed”, we thought it is best if we took a stat-based approach in this blog- to show you just how compelling the case is. Here you go- 5 tips with relevant stats that will push you to make guest experience your niche.That’s right! Treating your hotel guests, the way they should be, can actually bring in more business for you. Because that’s the one thing people look forward to! And for hotels, this starts even before the check-in happens! Right from the moment they start their search for a hotel, you should stand out for them. Their engagement with you should be consistent across all touchpoints- be it in the discovery phase, at the booking phase, pre-arrival, while checking-in, through their stay, at check-out and post departure. You cannot promise them the stars in your description online and then give them anything less during the other phases. Be modest in your approach- that’s ok, but be genuine with your service. Genuine service will always translate into more satisfaction. Or if you think elaborate & personalized hotel guest experience is your thing, then go ahead and blow their minds off with it. Point is, to do it consistently throughout their journey with you. Because, like we saw- if it feels right, they will give you their business.",
    img: "https://innofthelostcoast.com/wp-content/uploads/Deluxe-Spa-Suite-1.jpg",
    id: 6,
  },
];
